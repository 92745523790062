import React from 'react'
import productHeaderLeftImg from '../../../data/images/product-page/product_header_left.png'
import productHeaderRightImg from '../../../data/images/product-page/product_header_right.png'
import { graphql, useStaticQuery } from 'gatsby'
import { HeaderContainer, Title, ProductHeaderRight, ProductHeaderLeft, Logo, CenterBox } from './styles'

const ProductHeader = ({ title }) => {
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/big-logo.png" }) {
        childImageSharp {
          fixed(width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <HeaderContainer>
      <ProductHeaderLeft src={productHeaderLeftImg} />
      <CenterBox>
        <Title>{title}</Title>
        <Logo fixed={logo.file.childImageSharp.fixed} />
      </CenterBox>
      <ProductHeaderRight src={productHeaderRightImg} />
    </HeaderContainer>
  )
}

export default ProductHeader
