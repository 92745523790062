import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'
import SEO from '../components/seo'
import ProductHeader from '../templates/ProductPage/ProductHeader'

const MentionsLegales = () => {
  return (
    <>
      <SEO title="Mentions légales" description="Page Mentions légales" />
      <ProductHeader title='Mentions légales' />

      <Container>

        <ChapterBox>
          <SubTitle>Raisons sociales</SubTitle>
          <TextContent>
            Adresse Siège social : Café Jh And Co, 1 bis rue Blanqui,	94200 Ivry Sur Seine <br />
            Adresse Mail : contact@jhnco.fr <br />
            Immatriculée au RCS de R.C.S. Créteil 823 919 014 <br />
            Adresse site internet : jhnco.fr <br />
          </TextContent>
        </ChapterBox>

        <ChapterBox>
          <SubTitle>Traitement des informations personnelles</SubTitle>
          <TextContent>
            Conformément à la loi du 6 janvier 1978 relative à l’informatique, aux ﬁchiers et aux libertés, nous vous informons que vous
            disposez d’un droit d’accès, de modiﬁcation, de rectiﬁcation et de suppression des données qui vous concernent. <br /> <br />
            Pour cela écrivez à Société Café Jh And Co, 1 bis rue Blanqui, 94200 Ivry Sur Seine
          </TextContent>
        </ChapterBox>

        <ChapterBox>
          <SubTitle>Droits d’auteurs</SubTitle>
          <TextContent>
            En application de la loi du 11 mars 1957 et du code de la propriété intellectuelle du 1 er juillet 1992, toute reproduction
            partielle ou totale à usage collectif est strictement interdite sans autorisation de Café Jh And Co. <br /> Tous les éléments contenus
            sur ce site (texte, photos, visuels) sont la propriété de Jh'n'co et/ou de leurs détenteurs respectifs.

          </TextContent>
        </ChapterBox>

        <ChapterBox>
          <SubTitle>Developpement Web</SubTitle>
          <TextContent>
            Switch On Agency <br /><br />
            www.switchon.com <br />
            support@switchon.com
          </TextContent>
        </ChapterBox>

      </Container>
    </>
  )
}

export default MentionsLegales

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.xl}px) {
    max-width: 80%;
  }

  @media (max-width: ${breakpoints.s}px) {
    max-width: 90%;
  }
`

export const Title = styled.h1`
  text-align: center;
  color: #2B5C6B;
  font-size: 2.4em;
  display: block;
  padding: 0 2em;
  margin: 2em 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4em;
    padding: 0 1em;
  }
`

export const ChapterBox = styled.div`
  margin-bottom: 4em;
`

export const SubTitle = styled.p`
  color: #2B5C6B;
  font-size: 1.2em;
`

export const TextContent = styled.p`
  font-family: 'Avenir Light', serif;
  color: #4D4D4D;
`
