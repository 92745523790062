import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { breakpoints } from '../../../utils/styles'

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17em;
  background-image: linear-gradient(to right, #0b1d21 0%, #0b1d21 0.10353400000000003%, #2a5b6b 100%);
  margin-bottom: 9em;
`

export const ProductHeaderLeft = styled.img`
position: absolute;
top: 0;
left: 0;
width: 33%;
`
export const ProductHeaderRight = styled.img`
position: absolute;
top: 0;
right: 0;
width: 33%;
`

export const Title = styled.h2`
  display: inline-block;
  text-align: center;
  margin: 0;
  font-size: 1.8em;
  max-width: 15em;
  color: #eed7b8;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.8em;
    margin-bottom: .5em;
  }

  @media (max-width: 320px) {
    font-size: 2em;
    margin-bottom: .5em;
  }
`

export const CenterBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 10em;
`

export const Logo = styled(Img)`
bottom: -1em;
`
